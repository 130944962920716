<template lang="pug">
b-modal(id="small-modal-dk" v-model="showMe" centered=true hide-footer hide-header no-close-on-backdrop)
  .p-5
    .timer-container
      TimerComponent(:timer="timer" :showProgressBar="showProgressBarAun")
    h3.Title.text-center
      strong.normalFont.strong ¿Aún estás ahí?
    b-button(variant="bluecolmedica" block size="lg" type="button" @click="restartTimerWaiting" style="width: fit-content;").text-center.AuthsBtn.mb-4.mt-5
      p.buttonText.AuthMeds.mb-0 Sí, continuar
    .pt-3
      BottomTimer(:doThis="goToHome")
</template>

<script>
import BottomTimer from "./BottomTimer";
import TimerComponent from "./TimerComponent.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "AreYouThere",
  data() {
    return {
      showMe: true
    };
  },
  components: {
    BottomTimer,
    TimerComponent
  },
  computed: {
    ...mapState({
      showProgressBarAun: state =>
        state.virtualrow_atril_video.showProgressBarAun,
      timer: state => state.virtualrow_atril_video.timerAun
    })
  },
  methods: {
    ...mapActions({
      // Timer
      goToView: "virtualrow_atril_video/goToView",
      setExplanation: "virtualrow_atril_video/setExplanation",
      onStopTimer: "autoservice/onStopTimer"
    }),
    restartTimerWaiting() {
      this.$emit("restartAreYouThere");
    },
    goToHome() {
      this.restartTimerWaiting();
      this.onStopTimer(true);
      this.goToView({ view: "Home" });
      this.setExplanation(true);
    }
  }
};
</script>

<style>
.AuthMeds {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 65px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.AuthsBtn {
  margin: 100px auto;
  width: 324px;
  padding: 9px 3.5rem;
  line-height: 65px;
}

.timer-container {
  position: absolute;
  right: 30px;
  top: 30px;
}
</style>
