<template lang="pug">
b-modal(id="modal-content" v-model="showMe" centered=true hide-footer hide-header no-close-on-backdrop).modal-content
  .p-5#modal_inside_out
    .timer-container(@click="goToHome" v-if="showTimer")
      TimerComponent(:timer="timer" :showProgressBar="showProgressBarAun")
    h3.Title.text-center.pt-5
      .text-center.d-block.pb-5
        img(src="@/assets/kiosco46/warnIcon.svg" alt="warnIcon").infoIcon
      //- p(v-for="(block) in blocks_1" :key="block").buttonText.text-start.color-colmedica-gray.text-sizing-2.mt-0 {{ block }}
      p(v-for="(block) in blocks_2" :key="block").buttonText.text-start.color-colmedica-gray.mb-3.pb-1
        | {{ block }}
      p.buttonText.text-start.color-colmedica-gray.mb-3.pb-1(v-if="branchInfo.activeBranchMessage") {{ branchInfo.activeBranchMessage }}
      //- p.buttonText.text-start.color-colmedica-gray.mb-3.pb-1 Nuestro horario de atención es: Lunes a Viernes de 7:00 am a 6:30pm. Sábados de 7:00 am a 1:00pm. Domingos y Festivos no hay servicio
      p.buttonText.text-start.color-colmedica-gray.mb-3.pb-1 En este momento puedes radicar tu solicitud y enviaremos respuesta a tu correo electrónico.
      p.buttonText.text-start.color-colmedica-gray.mb-3.pb-1
        | Te invitamos a realizar tu solicitud a través de los diferentes canales:
      p.buttonText.m-0.color-colmedica-gray App
      p.buttonText.m-0.color-colmedica-gray Web
      p.buttonText.m-0.color-colmedica-gray WhatsApp 3175031111
      p.buttonText.m-0.color-colmedica-gray Línea de asistencia
    b-button(variant="bluecolmedica" block size="lg" type="button" @click="goToHome" style="width: fit-content;").text-center.AuthsBtn.mb-4.mt-5
      p.buttonText.AuthMeds.mb-0 Radicar solicitud
    .pt-3
      BottomTimer(:doThis="goToHome")
</template>

<script>
import BottomTimer from "./BottomTimer";
import TimerComponent from "./TimerComponent.vue";
import { mapActions, mapState } from "vuex";
import { getBranch, getCompany } from "../../../helpers/Meet";
import _get from "lodash/get";

export default {
  name: "AreYouThere",
  data() {
    return {
      showMe: false,
      blocks_2: [`En este momento no podemos atenderte.`],
      showTimer: true
    };
  },
  components: {
    BottomTimer,
    TimerComponent
  },
  async created(){
    this.evaluateAttention();
  },
  computed: {
    ...mapState({
      branchInfo: state => state.branchInfo,
      showProgressBarAun: state =>
        state.virtualrow_atril_video.showProgressBarAun,
      timer: state => state.virtualrow_atril_video.timerAun,
      env: state => state.env,
    }),

    company() {
      return this.env.VUE_APP_COMPANY;
    },

    branch() {
      return Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH);
    },
  },
  methods: {
    ...mapActions({
      // Timer
      goToView: "virtualrow_atril_video/goToView",
      setExplanation: "virtualrow_atril_video/setExplanation",
      onStopTimer: "autoservice/onStopTimer",
      putLog: "log/put",
      setBranchInfo: "setBranchInfo",
    }),
    goToHome() {
      this.showMe = false;
    },
    async evaluateAttention() {
      try {
        const company = await getCompany(this.company);
        this.putLog({
          name: "BranchValidation. Se obtuvo la información de la compañía."
        });
        const branch = _get(
          Object.keys(company).filter(
            name => company[name].branch_id === this.branch
          ),
          "[0]"
        );
        const branchInfo = await getBranch(this.company, branch);
        this.putLog({
          name: "BranchValidation. Se obtuvo la información de la sucursal."
        });
        this.setBranchInfo(branchInfo);
        this.continuesText = branchInfo.activeBranchMessage;
        if (branchInfo.activeBranch) {
          return this.showMe = false;
        }

        // Out of service
        this.enableButton = true;
        this.putLog({
          name: "BranchValidation. La sucursal está cerrada."
        });
        this.showMe = true;
        this.autoShowTime()
      } catch (error) {
        this.putLog({
          name:
            "BranchValidation. Error bloqueante en verificación de la compañía.",
          checked: false,
          message: error
        });
        this.errorMessage = true;
        console.error("Invalid company or branch validation", error);
      }
    },
    autoShowTime(){
      const timerCount = 20;
      const timeoutPrimary = setTimeout(() => {
        document.getElementById("modal_inside_out").onclick = () => {
          this.showTimer = false;
          const timer = setTimeout(() => {
            this.showTimer = true;
            clearTimeout(timer);
          }, timerCount);
        };
        clearTimeout(timeoutPrimary);
      }, timerCount);
    }
  }
};
</script>

<style>
.AuthMeds {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 65px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.AuthsBtn {
  margin: 100px auto;
  width: 324px;
  padding: 9px 3.5rem;
  line-height: 65px;
}

.timer-container {
  position: absolute;
  right: 30px;
  top: 30px;
}
</style>
