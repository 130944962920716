<template lang="pug">
.OutOperationsFS
  Header
  .OutOperationsFSPage
    .d-flex.justify-content-end.align-items-center.pb-4.px-2.pt-2
      Timer(:timer="timer" :showProgressBar="showProgressBar")
    .col-12.contain.color-colmedica-gray.mt-4
      h4.Title Tu solicitud ha sido radicada exitosamente
    .px-2.mt-3
      //- ol.orderList
      //-   li(v-for="list in orderList" :key="list")
      //-     p {{ list }}
      p.TextSection.buttonText.parr.mb-0.text-left
        strong Horario de atención de videollamada es:
      br
      p.TextSection.buttonText.parr.mt-0.text-left {{ showSchedule }}
      br
      p.buttonText.parr.text-left
        strong.color-colmedica-light Te responderemos tu solicitud dentro de las próximas 12 horas hábiles. 

      //- p.buttonText.parr.text-left.mt-0 Si deseas puedes agendar una videollamada para revisar tu solicitud con uno de nuestros asesores.

    //- p(style="margin: 30px auto; text-align: center;").color-colmedica-gray.text-sizing-1 Cerrando ventana...
</template>

<script>
import { mapState } from "vuex";
import Header from "./components/Header";
import asterisker from "@/helpers/asteriskHidden";
import _get from "lodash/get";
import Timer from "./components/Timer.vue";

export default {
  name: "OutOperationsFinish",
  components: {
    Header,
    Timer
  },
  computed: {
    ...mapState({
      branchInfo: state => state.autoservice.branchInfo,
      client: state => state.autoservice.client,
      timer: state => state.virtualrow_atril_video.timer,
      showProgressBar: state => state.virtualrow_atril_video.showProgressBar
    }),
    email() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}${
        splited[1] ? "@" + splited[1] : ""
      }`;
    },
    showSchedule() {
      return (
        this.branchInfo?.activeBranchMessage ||
        "De lunes a viernes de 7:00 am a 6:30 pm Sábados de 7:00 am a 1:00pm."
      );
    },
    orderList() {
      return [
        "Nuestro equipo la revisará dentro de las próximas 12 horas hábiles.",
        `Podrás hacer seguimiento del proceso en nuestra App Colmedica.`,
        `
          Recibirás a ${this.email} un correo de confirmación
          del número de radicación y fecha de respusesta.
        `,
        `
          De ser necesario, nos contactaremos contigo
          para ampliar información de la solicitud.
        `
      ];
    }
  }
};
</script>
<style scoped>
.OutOperationsFS {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: calc(100vh);
  padding-bottom: 105px;
}

.OutOperationsFSPage {
  background-color: #f2f4f8;
  padding: 50px;
  width: 100%;
  max-width: 600px;
}

.text-middle {
  margin: auto;
  width: 90%;
  margin-top: 35px;
  font-size: 17px;
  text-align: center;
  color: #00599d;
  top: 150px;
}

.parr {
  line-height: 31px;
}
.orderList {
  max-width: 700px;
  margin: auto;
  margin-top: 50px;
}
</style>
