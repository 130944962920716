<template lang="pug">
.VueSlickCarouselContainer.text-center
  #VueSlickCarousel.VueSlickCarousel(ref="carousel")
    slot
  .d-block.mb-3.mt-1
    button(
      v-for="(dot, index) in list"
      :key="index + 1"
      :id="index + 1"
      :class="(index === dotIndex) && 'selected_dot'"
      @click="changeSelectedDot(index)"
    ).dots.mx-1
</template>

<script>
export default {
  name: "VueSlickCarousel",

  data: () => ({
    slide: 1,
    observer: null,
    dotIndex: 0
  }),

  props: ["key2", "list"],

  mounted() {
    this.startObserver();
  },

  destroyed() {
    this.cleanObserver();
  },

  watch: {
    list(newVal, oldVal) {
      if (newVal && newVal.length) {
        const timeout = setTimeout(() => {
          this.cleanObserver();
          this.startObserver();

          if (newVal.length > oldVal.length) {
            this.dotIndex = newVal.length - 1;
          }

          if (newVal.length < oldVal.length) {
            if (this.key2 === newVal.length) {
              this.dotIndex = newVal.length - 1;
            } else {
              this.dotIndex = this.key2;
            }
          }
          clearTimeout(timeout);
        }, 50);
      }
    }
  },

  methods: {
    changeSelectedDot(dotIndex) {
      this.dotIndex = dotIndex;
      this.$refs.carousel.scrollLeft = dotIndex * 347.45;
    },
    handlerObserver(entries) {
      entries.forEach(entry => {
        const $tap = entry.target.querySelector("#tapDisabled");

        if (entry.isIntersecting) {
          $tap.classList.remove("disabled");
          $tap.classList.add("undisabled");
        } else {
          $tap.classList.remove("undisabled");
          $tap.classList.add("disabled");
        }
      });
    },
    cleanObserver() {
      const slickItems = document.querySelectorAll(".slick-item");

      slickItems.forEach(slickItem => {
        this.observer.unobserve(slickItem);
      });

      this.unobserve = null;
    },
    startObserver() {
      const slickItems = document.querySelectorAll(".slick-item");

      this.observer = new IntersectionObserver(this.handlerObserver, {
        threshold: 1.0
        // rootMargin: "100.45px"
      });

      slickItems.forEach(slickItem => {
        this.observer.observe(slickItem);
      });
    }
  }
};
</script>

<style scoped>
.VueSlickCarousel {
  display: flex;
  max-width: 100vw;
  overflow-x: auto;
  height: 100%;
  scroll-behavior: smooth;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  padding-left: 30px;
  padding-right: 60.45px;
}

.VueSlickCarousel::-webkit-scrollbar {
  display: none;
}

.slick-item {
  position: relative;
  max-width: 300px;
  min-width: 300px;
  width: 100%;
  margin-left: 47.45px;
  height: 300px;
  background-color: white;
  scroll-snap-align: center;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  border-radius: 13px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.tapDisabled {
  position: absolute;
  left: -14px;
  right: -19px;
  top: -16px;
  bottom: 0;
  z-index: 5;
}

.slick-item:last-child {
  margin-right: 100.45px;
}

.slick-item i {
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 4;
}

.dots {
  width: 15.53px;
  height: 15.53px;
  border-radius: 50%;
  background-color: #b8b8b8;
  display: inline-block;
  border: none;
  outline: none;
  transition: 0.2s background-color;
}

.selected_dot {
  background-color: var(--color-secondary);
}

.undisabled {
  display: none;
}

.disabled {
  display: block;
}

@media screen and (max-width: 411px) {
  .VueSlickCarousel {
    width: 100vw;
    position: relative;
    left: -32px;
  }
}
</style>
