<template lang="pug">
.Timer
  .containerTimer(style="--duration-static: 2.5; --size-static: 250;")
    //- TODO: In the future modified here duration
    .circle_mask
    .timer_container
      .timer(ref="timer")
        .mask
    .circle(ref="circle")
      slot
</template>

<script>
export default {
  name: "StaticTimer"
};
</script>

<style scoped>
.timer {
  background: -webkit-linear-gradient(
    left,
    rgb(102, 149, 187) 50%,
    rgb(178, 210, 238) 50%
  );
  border-radius: 100%;
  height: calc(var(--size-static) * 1px);
  width: calc(var(--size-static) * 1px);
  position: relative;
  animation: time calc(var(--duration-static) * 1s) steps(1000, start) infinite;
  -webkit-mask: radial-gradient(#813737 50%, #000 50%);
  mask: radial-gradient(#813737 50%, #000 50%);
}

.circle_mask {
  height: calc((var(--size-static) - 20) * 1px);
  width: calc((var(--size-static) - 20) * 1px);
  position: absolute;
  border-radius: 50%;
  background: var(--color-primary);
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  box-shadow: 0 0 3px 10px rgb(178, 210, 238);
}

.timer_container {
  animation: onVisible 0.2s ease-in-out alternate 1 forwards;
  margin-top: -50px;
}

.mask {
  border-radius: 100% 0 0 100% / 50% 0 0 50%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 50%;
  animation: mask calc(var(--duration-static) * 1s) steps(500, start) infinite;
  transform-origin: 100% 50%;
}

.Timer {
  height: 194px;
  position: absolute;
  left: 50%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  z-index: 13;
  padding-top: 100px;
}

.containerTimer {
  position: relative;
  width: fit-content;
  height: 151px;
}

.circle {
  height: calc((var(--size-static) - 20) * 1px);
  width: calc((var(--size-static) - 20) * 1px);
  position: absolute;
  border-radius: 50%;
  background: var(--color-primary);
  z-index: 5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  color: white;
  padding: 2rem;
  overflow: hidden;
}

.circle span {
  margin-left: 20px;
  color: white;
}

@keyframes time {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes mask {
  0% {
    background: rgb(178, 210, 238);
    transform: rotate(0deg);
  }
  50% {
    background: rgb(178, 210, 238);
    transform: rotate(-180deg);
  }
  50.01% {
    background: rgb(102, 149, 187);
    transform: rotate(0deg);
  }
  100% {
    background: rgb(102, 149, 187);
    transform: rotate(-180deg);
  }
}

@keyframes onVisible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
