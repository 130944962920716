<template lang="pug">
div(class="position-relative w-100 h-100", style="max-width: 100%")
  Header
  b-modal(v-model="showModal" hide-footer hide-header size="xl" centered no-close-on-backdrop id="autenticate")
    .ContainerModal
      .d-flex.justify-content-between.align-items-center.pb-4.px-2.pt-2
        img(@click="goToView({ view: 'Home' })" style="width: 28px;" src="@/assets/kiosco46/arrow.svg")
        Timer(:containerImageDocs="containerImageDocs" :timer="timer" :showProgressBar="showProgressBar")
      //- p.Subtitle.color-colmedica.text-center Ingresa el número de identificación
      //-   strong.ml-1 del usuario que requiere la autorización
      .OverlayStatus(v-if="loading || error")
        .ContainerInfo(v-if="loading")
          p.Subtitle.color-colmedica.text-center Validando datos, por favor espere
          LargeSpinner(:isFvVideo="true")
        .ContainerInfo(v-if="error")
          p.Subtitle.color-colmedica.text-center Lo sentimos, pero hubo un error en la validación de sus datos
          Error
          .d-flex.justify-content-center.mt-4.mb-0
            span(@click="error = false").fontBack.mb-0 &lt; Atrás
      .Panel.pb-4.pt-0.mt-2
        p(style="line-height: 32px;").Subtitle.weight-500.text-start.mb-4 Selecciona tipo de identificación
        .position-relative.mb-3
          b-form-select.mb-4.py-2.pl-3.panelInput(
            v-model="selected"
            :options="options"
            size="sm"
            required
            autocomplete="off").position-relative.selectStyles
          .position-absolute.arrowBottomStyles
            img(src="@/assets/kiosco46/svgArrowBottom.svg" alt="svgArrowBottom").svgArrowBottom
        p(style="line-height: 32px;").Subtitle.weight-500.text-start.mb-4 Ingresa el número de identificación del usuario que requiere atención
        input(
          readonly
          ref="idNumber"
          v-model="identificationNumber"
        ).panelInput.Subtitle.numberPanel
        div(:class="alphaNumeric ? 'grid-container--alpha' : 'ones'")
          .button_Grid--alpha(v-if="alphaNumeric")
            button.button_Panel(@click="() => writeOnPanel(item.toUpperCase())" v-for="item in panelAlpha" :key="item")
              | {{ item.toUpperCase() }}
            button.button_Panel.button123Borrar(@click="() => writeOnPanel('<')" style="font-size: 20px;") Borrar
            button.button_Panel.button123(@click="() => alphaNumeric = false"): strong 123
          .button_Grid(v-else)
            button.button_Panel(@click="() => writeOnPanel(1)") 1
            button.button_Panel(@click="() => writeOnPanel(2)") 2
            button.button_Panel(@click="() => writeOnPanel(3)") 3
            button.button_Panel(@click="() => writeOnPanel(4)") 4
            button.button_Panel(@click="() => writeOnPanel(5)") 5
            button.button_Panel(@click="() => writeOnPanel(6)") 6
            button.button_Panel(@click="() => writeOnPanel(7)") 7
            button.button_Panel(@click="() => writeOnPanel(8)") 8
            button.button_Panel(@click="() => writeOnPanel(9)") 9
            button.button_Panel(@click="() => alphaNumeric = true"): strong ABC
            button.button_Panel(@click="() => writeOnPanel(0)") 0
            button.button_Panel(@click="() => writeOnPanel('<')" style="font-size: 20px;") Borrar
        b-button(variant="bluecolmedica" block size="lg" type="button" @click="sendData" :disabled="identificationNumberValidation").text-center.AuthsBtn
          p.buttonText.m-0 Continuar
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold.vue";
import LargeSpinner from "../components/LargeSpinner";
import Error from "../components/Error";
import Timer from "./components/Timer.vue";
import LS from "../../helpers/localStorage";

export default {
  name: "Autenticate",
  props: ["newUser"],
  components: {
    Header,
    Back,
    Continue,
    LargeSpinner,
    Error,
    Timer
  },
  mounted() {
    this.setState({
      key: "documentsCropper",
      value: []
    });

    this.captchaElement = document.querySelector(".grecaptcha-badge");
    if (this.captchaElement) this.captchaElement.classList.add("show");

    // set number form scan
    let scanCed = LS.getItem("scanCed");
    if(scanCed) this.identificationNumber = String(scanCed);
  },
  beforeDestroy() {
    if (this.captchaElement) this.captchaElement.classList.remove("show");
  },
  data: () => ({
    captchaElement: null,
    debug: false,
    selected: "CC",
    number_id: "",
    alphaNumeric: false,
    listNumbers: [
      ["1", "2", "3"],
      ["4", "5", "6"],
      ["7", "8", "9"],
      ["null", "0", "X"]
    ],
    isCapcha: true,
    showModal: true,
    identificationNumber: "",
    loading: false,
    error: false,
    panelAlpha: [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z"
    ]
  }),
  destroyed() {
    this.selected = null;
    this.number_id = "";
  },
  watch: {
    newUser(val) {
      if (val) {
        this.loading = false;
      }
    },
    view(val) {
      if (val && (val === "IsOlder" || val === "IsOlderSecond")) {
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapState({
      options: state => state.virtualrow_atril_video.tipide,
      errors: state => state.autoservice.validationErrors,
      charging: state => state.autoservice.charging,
      env: state => state.env,
      view: state => state.virtualrow_atril_video.view,
      explanation: state => state.autoservice.explanation,
      stopTimer: state => state.autoservice.stopTimer,
      containerImageDocs: state =>
        state.virtualrow_atril_video.containerImageDocs,
      showProgressBar: state => state.virtualrow_atril_video.showProgressBar,
      timer: state => state.virtualrow_atril_video.timer,
      branch_id: state => state.branchInfo
    }),
    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },
    _number() {
      let number_id = this.number_id;
      if (number_id != "No. Identificación") {
        var convertedPhone = "******" + number_id.substring(6);
        if (number_id.length <= 6) {
          return number_id.replace(/\d/g, "*");
        }
        return convertedPhone;
      }
      return number_id;
    },
    identificationNumberValidation() {
      return this.identificationNumber
        ? !this.identificationNumber.trim()
        : this.identificationNumber;
    }
  },
  methods: {
    ...mapActions({
      goToView: "virtualrow_atril_video/goToView",
      captchaValidation: "autoservice/captchaValidation",
      updateB64Final: "autoservice/updateB64Final",
      authAndQueryColmedica: "autoservice/authAndQueryColmedica",
      demoValidation: "virtualrow_atril_video/demoValidation",
      authenticate: "virtualrow_atril_video/authenticate",
      setEnv: "virtualrow_atril_video/setEnv"
    }),
    ...mapMutations({
      setState: "autoservice/setState"
    }),
    removeNumber() {
      if (this.number_id != "") {
        var data = this.number_id.substring(0, this.number_id.length - 1);
        if (data.length) this.number_id = data.toString();
        else this.number_id = "";
      }
    },
    writeOnPanel(key) {
      this.identificationNumber = this.identificationNumber.trim();

      if (key === "<") {
        // this.identificationNumber += key;
        const toArray = this.identificationNumber.split("");
        toArray.splice(this.identificationNumber.length - 1, 1);

        this.identificationNumber = toArray.join("");
      } else {
        this.identificationNumber += key;
      }

      this.identificationNumber = this.identificationNumber + " " + " ";

      this.$refs.idNumber.focus({ preventScroll: false });
    },
    addNumero(pay) {
      if (this.number_id == "") this.number_id = pay.toString();
      else this.number_id = `${this.number_id}${pay}`;
    },
    async sendData() {
      if (!this.selected || this.charging) return;
      let toSend = {
        ti: this.selected,
        nu: this.identificationNumber.trim()
      };

      // set env to older man
      this.setEnv(this.env);

      this.loading = true;
      this.updateB64Final(toSend);
      this.$refs.idNumber.blur();
      if (this.demoMode) return this.demoValidation(this.identificationNumber);

      await this.authenticate({
        NumeroIdentificacion: this.identificationNumber,
        TipoIdentificacion: this.selected
        // CodigoOficina: this.branch_id.branch_id
      }).catch(error => {
        console.error("Error validating information", error);
        this.loading = false;
        this.error = true;
      });
      // return this.authAndQueryColmedica(toSend);
    }
  }
};
</script>
<style scoped>
.TitleModal {
  font-size: 30px;
}

.contain {
  margin-top: 50px;
  position: relative;
  width: 100%;
  height: calc(100% - (58px + 22px + 50px));
}

.text-middle {
  margin-top: 25px;
  font-size: 15px;
  text-align: center;
}

.panelInput {
  border: 1px solid var(--color-gray);
  width: 100%;
  border-radius: 18px;
  padding: 25px;
  font-size: 35px;
  background: none;
  position: relative;
  z-index: 2;
  height: 104.5px;
}

.numberPanel {
  letter-spacing: 7px;
  font-weight: bold;
}

.arrowBottomStyles {
  right: 10px;
  padding-right: 10px;
  top: 40px;
  background: none;
}

select.panelInput {
  height: 104.5px;
}

.Panel {
  margin: 0 32px;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
}

.button_Grid,
.button_Grid--alpha {
  display: grid;
  grid-template-columns: repeat(3, 83.5px);
  justify-items: center;
  margin: auto;
  gap: 20px;
  margin-top: 70px;
  margin-bottom: 70px;
}

.button_Grid--alpha {
  /* width: 100%; */
  grid-template-columns: repeat(5, 83.5px);
  justify-content: center;
}

.button_Panel {
  height: 83.5px;
  width: 100%;
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: 20px;
  border: none;
  font-size: 32px;
  font-weight: bold;
}

.svgArrowBottom {
  width: 30px;
}

.AuthsBtn {
  margin: auto;
  width: 250px;
  padding: 9px 3.5rem;
  line-height: 65px;
  font-weight: 600;
}

.DeleteBoutton_Panel {
  grid-column: 1 / 3;
}

.OverlayStatus {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background: rgba(255, 255, 255, 0.877);
  /* opacity: 0.95; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ContainerInfo {
  text-align: center;
}

/*  Keyboard  */
.key-board {
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row-numbers {
  margin: 4px auto;
  display: block;
  justify-content: center;
}
.number-input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 14px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}
.number-button {
  background-color: #0057a0;
  color: white;
  font-size: 20px;
  margin: 0px 10px;
  width: 35px;
  height: 35px;
  border-radius: 9px;
}
.null-button {
  user-select: none;
  background-color: white;
  color: white;
  border: none;
  font-size: 20px;
  margin: 0px 10px;
  width: 35px;
  height: 35px;
  border-radius: 9px;
}
/* Capcha config */
.capcha-contain {
  margin-top: 20px;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

.Subtitle2 {
  font-size: 45px;
}

.button123 {
  grid-column: 6 / 5;
}

.button123Borrar {
  grid-column: 4 / 5;
}

.number-input {
  display: block;
  font-size: 14px;
  text-align: start;
  width: 100%;
  border-radius: 10px;
  height: auto;
  font-weight: bold;
  font-size: var(--font-sm);
  background: none;
  /* background-color: var(--color-white); */
  border: 1px solid var(--color-gray);
  color: var(--color-dark);
}

.ones {
  display: flex;
  justify-content: center;
}

/* .ContainerModal {
  padding: 50px;
} */
</style>
