<template lang="pug">
.NewUser
  .NewUserOverlay
  .Modal.NewUserModalContent
    header.Modal_header.py-3.p-1.d-flex.justify-content-center.align-items-center.position-relative
      .centeredInfo.d-flex.justify-content-center.align-items-center.mt-2.mb-1
        svg(width='23' height='23' viewbox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M0.85 11.5C0.85 5.61832 5.61792 0.850249 11.4995 0.85C17.3791 0.856526 22.1438 5.62136 22.15 11.5009C22.1495 17.3823 17.3815 22.15 11.5 22.15C5.61817 22.15 0.85 17.3818 0.85 11.5Z' stroke='white' stroke-width='1.7')
          path(d='M11.5013 6.71094C10.9558 6.71094 10.543 6.99787 10.543 7.46132V12.6705C10.543 13.1344 10.9557 13.4204 11.5013 13.4204C12.0335 13.4204 12.4596 13.1224 12.4596 12.6705V7.46132C12.4596 7.00887 12.0335 6.71094 11.5013 6.71094ZM11.5013 14.378C10.9772 14.378 10.5506 14.8079 10.5506 15.3366C10.5506 15.8644 10.9772 16.2943 11.5013 16.2943C12.0254 16.2943 12.4515 15.8644 12.4515 15.3366C12.4515 14.8079 12.0254 14.378 11.5013 14.378Z' fill='#F4F4F4')
        h4.m-0.ml-2.Title(style="color: white;").ml-2 Usuario no activo
        button(
          @click="() => goToView({view: 'Autenticate'})"
        ).button-without-styles
          svg(width='20' height='21' viewbox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg').position-absolute.svgCloseIcon
            path(d='M2.49023 17.7656L17.2565 2.99936' stroke='#F4F4F4' stroke-width='4.55622' stroke-linecap='round' stroke-linejoin='round')
            path(d='M2.49023 3.23438L17.2565 18.0006' stroke='#F4F4F4' stroke-width='4.55622' stroke-linecap='round' stroke-linejoin='round')
    section.Modal_content.px-4.text-center
      p.Subtitle.py-3 Asegurate de ingresar los datos correctos del usuario que requiere autorización
    footer.Modal_footer.px-4.pb-3.mx-2
      b-button(variant="bluecolmedica" block size="lg" type="button" @click="() => goToView({view: 'Autenticate'})").text-center.AuthsBtn
        p.buttonText.m-0 Ingresar nuevos datos
      a.buttonText.my-4(@click="() => goToView({view: 'Afiliate'})") Afiliate a Colmédica
    p.p-3(v-if="view === 'NewUser' && !showAreYouThere")
      BottomTimer(:doThis="onShowAreYouThere")
  div(v-if="showAreYouThere")
    AreYouThere(@restartAreYouThere="restartTimerWaiting")
</template>

<script>
import { mapActions, mapState } from "vuex";
import BottomTimer from "./components/BottomTimer";
import AreYouThere from "./components/AreYouThere.vue";

export default {
  name: "NewUser",

  data() {
    return {
      showAreYouThere: false,
      timer20: null
    };
  },

  components: {
    BottomTimer,
    AreYouThere
  },

  computed: {
    ...mapState({
      view: state => state.virtualrow_atril_video.view,
      showProgressBarAun: state =>
        state.virtualrow_atril_video.showProgressBarAun,
      timerAun: state => state.virtualrow_atril_video.timerAun
    })
  },

  destroyed() {
    if (this.timer20) clearTimeout(this.timer20);
  },

  methods: {
    ...mapActions({
      goToView: "virtualrow_atril_video/goToView"
    }),
    onShowAreYouThere() {
      this.showAreYouThere = true;
    },
    restartTimerWaiting() {
      this.showAreYouThere = false;
      this.validationRestart();
    },
    validationRestart() {
      if (this.timer20) {
        clearTimeout(this.timer20);
        this.timer20 = null;
      }

      this.timer20 = setTimeout(() => {
        this.showAreYouThere = true;
        clearTimeout(this.timer20);
        this.timer20 = null;
      }, 120000);
    }
  }
};
</script>

<style scoped>
.NewUserModalContent {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1220;
  background-color: aliceblue;
  border-radius: 20px;
}

.NewUserOverlay {
  background-color: rgba(0, 0, 0, 0.322);
  z-index: 1050;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}

.Header {
  background-color: #e2574c;
  padding: 20px;
}

.Modal_header {
  background-color: var(--color-bad);
  border-radius: 20px 20px 0 0;
}

.centeredInfoH4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 22px;

  text-align: center;
  letter-spacing: -0.408px;

  color: var(--color-white);
}

.svgCloseIcon {
  right: 20px;
  top: 20px;
}

.Modal_content {
  font-weight: 400;
  font-size: 30px;

  margin-top: 28px;
  margin-bottom: 19px;
  line-height: 30px;
}

.Modal_footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Modal_footer_button {
  color: white;
  background-color: var(--color-secondary);
  border-radius: 19.6226px;
  border: none;
  display: block;
  font-size: 30px;
}

.afiliateText {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;
  font-size: 30px;
  margin-top: 20px;

  color: var(--color-secondary);
}

.AuthsBtn {
  margin: auto;
  width: 400px;
  padding: 9px 3.5rem;
  line-height: 65px;
  font-weight: 600;
}
</style>
