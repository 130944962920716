<template lang="pug">
div.Home
  Header
  .Page
    .HomePage(v-show="explanation")
      .d-flex.justify-content-between.align-items-center
        img(style="width: 28px; opacity: 0;" src="@/assets/kiosco46/arrow.svg")
        TimerHome(:containerImageDocs="containerImageDocs" :timer="timer" :showProgressBar="showProgressBar")
      .col-12.contain.color-colmedica-gray.mt-0
        .pb-4.pt-4
          h3.Subtitle.Auth Autorizaciones médicas
        .pt-5
          b-button.my-4.align-self-center.mx-auto(
            v-for="service in services"
            :key="service.code_name"
            variant="bluecolmedica"
            @click="setService(service)"
            size="sm"
            class="home-service-button"
            block
          )
            p.text-sizing-lg.AuthMeds.mb-0 {{ service.label }}
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Header from "./components/Header";
import { getOneTimeToken } from "@/helpers/Meet";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold.vue";
import Timer from "./components/Timer.vue";
import TimerHome from "./components/TimerHome.vue";
import LS from "../../helpers/localStorage";

export default {
  name: "Home",
  components: {
    Header,
    Back,
    Continue,
    Timer,
    TimerHome
  },
  props: {
    inPresentation: {
      required: true
    }
  },
  data: () => ({
    video: undefined
  }),
  destroyed() {
    document.body.removeEventListener("click", this.setExpTrue);
  },
  mounted() {
    this.startup();
    this.setExplanation(false);

    const timer = setTimeout(() => {
      this.setExpTrue();
      document.body.addEventListener("click", this.setExpTrue);
      clearTimeout(timer);
    }, 500);

    if (this.documents.length) {
      this.setState({
        key: "files",
        value: []
      });
    }
  },
  methods: {
    ...mapMutations({
      setState: "autoservice/setState"
    }),
    ...mapActions({
      goToView: "virtualrow_atril_video/goToView",
      setExplanation: "virtualrow_atril_video/setExplanation",
      selecteService: "autoservice/selecteService",
      updateB64Final: "autoservice/updateB64Final",
      onStopTimer: "autoservice/onStopTimer",
      setKiosco: "setKiosco",
    }),
    setExpTrue() {
      this.setExplanation(true);
    },
    async startup() {
      let to = await getOneTimeToken();
      let ma = null;
      let fu = `${this.$route.path.substring(1)}${this.$route.query?.branchid ? '_' + this.$route.query?.branchid : ''}`;
      let su = Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH);
      let tr = Number(this.env.VUE_APP_DEBMEDIA_KIOSCO64_TRAMITE);

      let jsonFinal = { to, tr, su, fu, ma };
      this.updateB64Final(jsonFinal);
      this.video = this.$el.querySelector("#video");
      
      // remove localstorage for kiosko 46 only
      localStorage.clear();

      // For kiosco validations only
      this.setKiosco(true);

      LS.setItem("sucursal", this.$route.query?.branchid);
      LS.setItem("tramite", tr);

    },
    setService(service) {
      if (this.inPresentation) return;
      this.setExplanation(false);
      this.selecteService(service);
      this.goToView({ view: "Autenticate" });
    },
    nextView() {
      if (this.inPresentation) return;
      this.setExplanation(false);
      this.onStopTimer(false);
      // this.video.pause();
    }
  },
  computed: {
    ...mapState({
      documents: state => state.autoservice.files,
      services: state => state.autoservice.servicesAvailable,
      explanation: state => state.virtualrow_atril_video.explanation,
      env: state => state.env,
      stopTimer: state => state.autoservice.stopTimer,
      containerImageDocs: state =>
        state.virtualrow_atril_video.containerImageDocs,
      showProgressBar: state => state.virtualrow_atril_video.showProgressBar,
      timer: state => state.virtualrow_atril_video.timer
    })
  }
};
</script>
<style scoped>
.AuthMeds {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 65px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.AuthsBtn {
  margin: 100px auto;
  width: 400px;
  padding: 9px 3.5rem;
  line-height: 65px;
}

.Auth {
  margin: 0;
  line-height: 65px;
  text-align: center;
}

.contain {
  margin-top: 50px;
}

#video {
  width: 100%;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 82px;
  max-width: 956px;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
}

.botton-actions .containerTimer {
  position: absolute;
  left: 5px;
  bottom: 5px;
}

.home-service-button {
  font-size: 20px;
  font-weight: 100;
  padding: 22px 3.5rem;
  max-width: 575px;
  font-weight: 600;
  width: 100%;
}

.HomePage {
  background-color: #f2f4f8;
  padding: 50px;
  width: 100%;
  max-width: 812px;
}

.HomePage--New {
  background-color: #f2f4f8;
  padding: 50px;
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 707px;
}

.my-2 {
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
}

.text-sizing-lg {
  font-size: 35px;
}

.HomePage .Subtitle {
  font-size: 50px;
}
</style>
