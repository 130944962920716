<template lang="pug">
div
  .IsOlderSecondOverlay
  #IsOlderSecondModal.IsOlderSecondModal.p-4.text-center
    img(src="@/assets/kiosco46/redalert.svg" alt="redalert").redalert.py-4
    p.buttonText.mb-0 Canal exclusivo para mayores de
    p.buttonText.pb-3 60 años
    //- p.buttonText.pb-3 Este canal es para mayores de {{env.VUE_APP_OLD_AGE}} años
    p.buttonText Te invitamos a realizar tu solicitud a través de los diferentes canales:
    .pt-2.pb-4
      p.buttonText.m-0 Código QR
      .qrcode_container.mt-3.mb-4
        #qrcode
      p.buttonText.m-0 App
      p.buttonText.m-0 Web
      p.buttonText.m-0 WhatsApp 3175031111
      p.buttonText.m-0 Línea de asistencia
    b-button(variant="bluecolmedica" block size="lg" type="button" @click="() => goToView({ view: 'Autenticate' })").AuthsBtn.text-center
      p.buttonText.m-0 Ingresar nuevos datos
    .p-3(v-if="view === 'IsOlderSecond' && !showAreYouThere")
      BottomTimer(:doThis="onShowAreYouThere" $element="#IsOlderSecondModal")
    div(v-if="showAreYouThere")
      AreYouThere(@restartAreYouThere="restartTimerWaiting")
</template>

<script>
import { mapActions, mapState } from "vuex";
import BottomTimer from "./components/BottomTimer";
import AreYouThere from "./components/AreYouThere.vue";
import QrCode from "easyqrcodejs";

export default {
  name: "IsOlderSecond",

  data() {
    return {
      showAreYouThere: false,
      timer20: null
    };
  },

  components: {
    BottomTimer,
    AreYouThere
  },

  mounted() {
    const options = {
      text: "https://www.oficina.virtualcolmedica.com/",
      width: 150,
      height: 150
    };
    new QrCode(document.getElementById("qrcode"), options);
  },

  computed: {
    ...mapState({
      view: state => state.virtualrow_atril_video.view,
      env: state => state.env,
      showProgressBarAun: state =>
        state.virtualrow_atril_video.showProgressBarAun,
      timerAun: state => state.virtualrow_atril_video.timerAun
      // options: state => state.olderAdult.tipide,
      // branch_id: state => state.branchInfo,
      // showUserInactive: state => state.olderAdult.showUserInactive,
      // showUserOlder: state => state.olderAdult.showUserOlder,
      // client: state => state.olderAdult.client,
      // charging: state => state.olderAdult.charging
    })
  },

  methods: {
    ...mapActions({
      goToView: "virtualrow_atril_video/goToView"
    }),
    onShowAreYouThere() {
      this.showAreYouThere = true;
    },
    restartTimerWaiting() {
      this.showAreYouThere = false;
      this.validationRestart();
    },
    validationRestart() {
      if (this.timer20) {
        clearTimeout(this.timer20);
        this.timer20 = null;
      }

      this.timer20 = setTimeout(() => {
        this.showAreYouThere = true;
        clearTimeout(this.timer20);
        this.timer20 = null;
      }, 120000);
    }
  }
};
</script>

<style lang="scss" scoped>
.IsOlderSecondOverlay {
  background-color: rgba(0, 0, 0, 0.322);
  z-index: 1050;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}

.IsOlderSecondModal {
  position: fixed;
  top: 48%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1220;
  background-color: aliceblue;
  border-radius: 20px;
}

.AuthsBtn {
  margin: auto;
  width: 450px;
  padding: 9px 3.5rem;
  line-height: 65px;
  font-weight: 600;
}

.redalert {
  max-width: 48px;
  width: 100%;
}

.qrcode_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
