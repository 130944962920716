<template lang="pug">
div(class="position-relative w-100 h-100", style="max-width: 100%")
  div(style="margin: 0 12%;")
    Header
    b-modal(v-model="showPrincipalModal" id="small-modal-dk-over" hide-footer hide-header size="xl" centered no-close-on-backdrop)
      .contain(v-if="isSending")
        StaticTimer
          p(v-for="fi in filesIndexes" :style="'transform: translateY(-' + fi * 45 + 'px) translateX(-50%); opacity: 0.5;'").PAnimated {{ fi }}
        h3.Subtitle.d-block: strong Estamos enviando {{ files.length > 1 ? 'tus documentos' : 'tu documento' }}
        h3.Subtitle.d-block y alistando tu videollamada
        .pb-5
        .pb-5
        .pb-5
        .my-5.maxSizingProgress
          Spinner
          //- img.maxSizingProgress.imageContentable(src="@/assets/spinner.gif")
          //- progress-bar(class="maxSizingProgress" type="circle" :ref="progress_sending" :options="options")
      //- .contain(v-else)
      //-   div(class="text-middle")
      //-     span(class="d-block").text-middle {{ files.length>1 ? 'Tus' : 'Tu' }}  
      //-       strong.text-middle(v-show="files.length>1") {{ files.length }} 
      //-       span.text-middle {{ files.length>1 ? 'documentos' : 'documento' }} 
      //-       strong.text-middle {{ files.length>1 ? 'han' : 'ha' }} 
      //-     b(class="d-block").text-middle {{ files.length>1 ? 'sido enviados' : 'sido enviado' }}
      //-     span(class="d-block").text-middle exitosamente
      //-   div(class="text-center")
      //-     img(src="./../../assets/like.png" class="sending-image")
      //-   .d-flex.justify-content-center.mt-2.relative.okContainer
      //-     ButtonTimer(:eventAction="requestVideoCall")
      //-       p.fontOk OK
</template>
<script>
import { mapActions, mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
// import { progressUpdated } from "@/utils/calculator";
import { utf8_to_b64 } from "@/utils/decoder";
import { getOneUseToken } from "@/helpers/Meet";
import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold.vue";
import _get from "lodash/get";
import Spinner from "../components/Spinner";
import StaticTimer from "./components/StaticTimer";
import ButtonTimer from "./components/ButtonTimer";

export default {
  name: "ConfirmUpload",
  components: {
    Header,
    Continue,
    Spinner,
    StaticTimer,
    ButtonTimer
  },
  data: () => ({
    isSending: true,
    options: {
      color: "rgb(92, 147, 194)",
      strokeWidth: 8,
      trailColor: "rgb(205, 222, 236)",
      trailWidth: 8,
      fill: "rgb(0, 87, 160)"
    },
    progress_sending: uuidv4(),
    state_progress: null,
    timingController: 3,
    filesIndexes: [],
    showPrincipalModal: true
  }),
  mounted() {
    this.sendFiles();
    this.evaluateDocs();
  },
  computed: {
    ...mapState({
      files: state => state.autoservice.files,
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.virtualrow_atril_video.service,
      HorarioOutContinue: state => state.autoservice.HorarioOutContinue,
      env: state => state.env,
      client_video: state => state.virtualrow_atril_video.client,
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    name() {
      let pureName = (_get(this.client, "Nombres") || "").split(" ");
      let pureLastName1 = _get(this.client, "PrimerApellido") || "";
      let pureLastName2 = _get(this.client, "SegundoApellido") || "";
      return [...pureName, pureLastName1, pureLastName2]
        .filter(data => !!data)
        .join(" ");
    }
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      updateB64Final: "autoservice/updateB64Final",
      guardarSolicitudColmedica: "autoservice/guardarSolicitudColmedica",
      guardarSolicitudDemo: "autoservice/guardarSolicitudDemo",
      putLog: "log/put",
      setClient: "autoservice/setClient",
    }),
    evaluateDocs() {
      for (
        let i = 1, index = this.files.length;
        i <= this.files.length;
        i++, index--
      ) {
        setTimeout(() => {
          this.filesIndexes = [...this.filesIndexes, index];
        }, 50 * i);
      }
    },
    endTiming() {
      if (this.state_progress) this.state_progress.cancel();
      this.isSending = false;
    },
    async requestVideoCall() {
      if (this.HorarioOutContinue) {
        this.goToView("OutOperationsFinish");
      } else {
        let to = await getOneUseToken();
        let jsonB64 = JSON.stringify({
          ...this.b64final,
          ap: this.client.PrimerApellido +' ' + this.client.SegundoApellido,
          sv: this.service,
          to
        });
        this.$router.push({
          name: "DecoderKiosco46",
          query: { pr: utf8_to_b64(jsonB64), branchid: this.$route.query.branchid }
        });
      }
    },
    async sendFiles() {
      // this.state_progress = progressUpdated(
      //   this.$refs[this.progress_sending],
      //   this.timingController,
      //   this.endTiming
      // );

      // set client on virtualrow
      await new Promise(r=>{
        setTimeout(() => {
          this.setClient(this.client_video);
          r();
        }, 1000);
      })

      let code;
      try {
        if (this.demoMode) code = await this.guardarSolicitudDemo();
        else code = await this.guardarSolicitudColmedica();
        this.updateB64Final({
          so: code,
          na: this.name
        });
        this.putLog({
          name: "ConfirmUpload. Archivos subidos a la plataforma."
        });
        this.requestVideoCall();
      } catch (error) {
        this.putLog({
          name: "ConfirmUpload. No se subieron los archivos a la plataforma.",
          checked: false,
          message: error
        });
        console.error("Invalid request of documents.", error);
      }
      this.isSending = false;
    }
  },
  destroyed() {
    this.progress_sending = uuidv4();
    this.state_progress = null;
  }
};
</script>

<style scoped>
.contain {
  margin-top: 40px;
  position: relative;
  text-align: center;
  width: 100%;
  height: calc(100% - (58px + 22px + 50px));
}
.text-middle {
  margin-top: 25px;
  font-size: var(--font-md-lg);
  text-align: center;
  color: #00599d;
}
.maxSizingProgress {
  display: flex;
  justify-content: center;
  max-width: 130px;
  margin: 0px auto;
}
.sending-image {
  margin-top: 80px;
  width: 326px;
}
.imageContentable {
  object-fit: center;
  object-position: center;
}

.Subtitle strong {
  font-size: var(--font-lg);
}

.timerContent {
  width: 350px;
  height: 350px;
  background-color: var(--color-primary);
  color: white;
  border-radius: 50%;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

/* .timerContent {
  color: red;
} */

.PAnimated {
  transition: 1s all;
  opacity: 0;
  font-size: var(--font-xl);
  left: 50%;
  font-weight: bold;
  position: absolute;
  /* animation: toTop 1s 1 ease-in-out forwards; */
}

.PAnimated:last-child {
  top: 18%;
  font-size: 150px;
  opacity: 1 !important;
  /* animation: toTop2 1s 1 ease-in-out forwards; */
}

.fontOk {
  font-size: var(--font-md-l);
  font-weight: bold;
  margin: 0;
  margin-left: -25px;
}

.okContainer {
  position: relative;
  padding-top: 7rem;
}

@keyframes toTop {
  0% {
    opacity: 0;
    transform: scale(1, 1) translateX(-50%);
  }
  100% {
    transform: scale(0.8, 0.8) translateX(-50%);
    top: 0;
    opacity: 0.8;
  }
}

@keyframes toTop2 {
  0% {
    opacity: 0;
    transform: scale(1, 1) translateX(-50%);
  }
  100% {
    transform: scale(1, 1) translate(-50%, -50%);
    top: 50%;
    opacity: 0.8;
  }
}

#small-modal-dk-over___BV_modal_content_ {
  max-width: 640px;
  /* margin: 10px; */
  position: absolute;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
}

#small-modal-dk-over___BV_modal_content_ {
  top: 55% !important;
  transform: translateX(-50%);
  height: fit-content;
}
</style>
