<template lang="pug">
div(:class="isFvVideo ? 'lds-ring--fv' : 'lds-ring'")
  div
  div
  div
</template>

<script>
export default {
  name: "MiniSpinner",
  props: ["isFvVideo"]
};
</script>

<style scoped>
.lds-ring,
.lds-ring--fv {
  display: inline-block;
  position: relative;
  width: 258px;
  height: 256px;
}

.lds-ring--fv {
  width: 134px;
  height: 130px;
}

.lds-ring div,
.lds-ring--fv div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 260px;
  height: 260px;
  margin-top: 0;
  border: 25px solid var(--color-primary);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color-primary) transparent transparent transparent;
}

.lds-ring--fv div {
  width: 140px;
  height: 140px;
  border: 15px solid var(--color-primary);
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color-primary) transparent transparent transparent;
}

.lds-ring div:nth-child(1),
.lds-ring--fv div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2),
.lds-ring--fv div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3),
.lds-ring--fv div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
