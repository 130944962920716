<template lang="pug">
.Content
  .Afiliate
    Header
    .Page
      .AfiliatePage.text-center
        .d-flex.justify-content-between.align-items-center
          img(@click="goToView({view: 'Autenticate'})" style="width: 28px;" src="@/assets/kiosco46/arrow.svg")
          Timer(:containerImageDocs="containerImageDocs" :timer="timer" :showProgressBar="showProgressBar")
        h2.Title.color-colmedica.text-center Afiliate a Colmédica
        p.Subtitle.mb-0.mt-3 Comunícate con nuestra línea de atención comercial y conoce todas las opciones que tenemos para ti y tu familia:
        p.Subtitle 301 394 45 50
        p.Subtitle.mb-0 El horario de atención para afiliaciones a Colmédica es:
        p.Subtitle.mb-0 Lunes a viernes: 7:00 a.m. a 7:00 p.m
        p.Subtitle Sábados:  9:00 a.m. a 1:00 p.m.
        //- p.Subtitle.mb-0 O ingresa a nuestro sitio web
        //- p.mb-0: a(href="https://www.colmedica.com/planes" target="_blank" style="font-size: 28px;").Link.Subtitle https:// www.colmedica.com/planes
        //- p.Subtitle.mb-0.px-3 y solicita una llamada para obtener una asesoría.
</template>

<script>
import { mapActions, mapState } from "vuex";
import Header from "./components/Header";
import Timer from "./components/Timer.vue";

export default {
  name: "Afiliate",
  components: {
    Header,
    Timer
  },
  methods: {
    ...mapActions({
      goToView: "virtualrow_atril_video/goToView"
    })
  },
  computed: {
    ...mapState({
      explanation: state => state.autoservice.explanation,
      stopTimer: state => state.autoservice.stopTimer,
      containerImageDocs: state =>
        state.virtualrow_atril_video.containerImageDocs,
      showProgressBar: state => state.virtualrow_atril_video.showProgressBar,
      timer: state => state.virtualrow_atril_video.timer
    })
  }
};
</script>

<style lang="scss" scoped>
.Link {
  color: var(--color-secondary);
}

.TitleModal {
  font-size: 75px;
}

.TextSection,
.Link {
  line-height: 50px;
  font-size: 40px;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 82px;
  max-width: 956px;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
}

.AfiliatePage {
  background-color: #f2f4f8;
  padding: 50px;
  width: 100%;
  max-width: 600px;
  margin: auto;
}
</style>
