<template lang="pug">
  .Content
    Header
    .Overlay#OverlayDocument(style="display: none;")
    .ModalContent#AddDocument
      .position-relative.w-auto(style="height: 94%;")
        .position-relative.pt-4.d-flex.justify-content-between.pb-2
          button(@click="onDiscard" v-if="!modeCropper").button-without-styles.ml-4
            img(src="@/assets/kiosco46/cancel_white.svg" alt="cancel" @click="showModalScan = false").cancelIcon
          span
          //- button(@click="addDocument()").button-without-styles.mr-4
          //-   svg(width='22' height='16' viewbox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg')
          //-     path(d='M2 8L8 14L20 2' stroke='#0058A2' stroke-width='3.12' stroke-linecap='round' stroke-linejoin='round')
        .d-block.text-center.Background
          .tapeControl
          figure(v-if="holdIsImagen").m-0.containerImage.mx-auto.block.pt-5.mt-4
            img(
              :src="urlData"
              alt="imageUser"
              ref="fileImage"
              v-show="cropper"
            ).object-fit-cover.w-100.h-100.p-16.imageModal#generalImage
          .pt-4.mb-4.TextSection(v-if="!holdIsImagen") Sin vista previa del documento
          div(v-if="!holdIsImagen")
            b-button(
              variant="bluecolmedica"
              @click="addDocument()"
              v-if="!modeCropper"
              size="sm").mb-4 Usar imagen
          .zone-button(v-if="holdIsImagen")
            .mx-5
              div(v-if="compressImagen == false")
                .p-2.d-flex.justify-content-around.w-100.mt-3.mb-0(v-if="!modeCropper || holdLoading")
                  .circleCard.mr-3.d-flex.text-center
                    div(@click="() => onRotation(-90)")
                      img(src="@/assets/kiosco46/left.svg" alt="left").iconCrop
                    p.textCard.mt-1 Izquierda
                  .circleCard.ml-2.d-flex.text-center
                    div(@click="() => onRotation(90)")
                      img(src="@/assets/kiosco46/right.svg" alt="right").iconCrop
                    p.textCard.mt-1 Derecha
                  .circleCard.ml-4.d-flex.text-center
                    div(@click="() => activateCropper(true)")
                      img(src="@/assets/kiosco46/crop.svg" alt="crop").iconCrop
                    p.textCard.mt-1 Recortar
                //- .pb-4
                b-button(v-if="!modeCropper" variant="bluecolmedica" block size="lg" type="button" @click="addDocument()" style="width: fit-content;").text-center.AuthsBtn.mt-4.mb-5
                  p.buttonText.AuthMeds.mb-0 Usar imagen
                .p-2.d-flex.justify-content-around.w-100.mt-3.mb-1(v-else)
                  .circleCard.mr-3.d-flex.text-center
                    div(@click="onCancel()")
                      img(src="@/assets/kiosco46/cancel_2.svg" alt="cancel_2").iconCrop
                    p.textCard.mt-1 Cancelar
                  .circleCard.ml-2.d-flex.text-center
                    div(@click="onAccept()")
                      img(src="@/assets/kiosco46/accept.svg" alt="accept").iconCrop
                    p.textCard.mt-1 Aceptar
      .pb-4.mx-5(v-if="addDocumentShow")
        BottomTimer(:doThis="onShowAreYouThere" $element="#AddDocument" :whiteC="true" :limit="40")
    div(v-if="showAreYouThere")
      AreYouThere(@restartAreYouThere="restartTimerWaiting")
    .Page
      .contain.UploadFilesPage(:class="(documents && documents.length) && 'down'")
        .containerPage
          .d-flex.justify-content-between.align-items-center
            img(@click="backView" style="width: 28px;" src="@/assets/kiosco46/arrow.svg")
            Timer(:containerImageDocs="containerImageDocs" :timer_anim="40" :timer="timer" :showProgressBar="showProgressBar")
            //- img(v-else src="@/assets/kiosco46/home.png" alt="home").home
          h2.TitleSection.text-start.pl-0.ml-0.pt-1#headerCalendarTitle Adjunta documentos
          //- .botton-actions.ml-4.pl-2.pt-1
          //-   .d-flex.justify-content-between
          //-     button(type="button" @click="documents.length == 0 ? backView() : $bvModal.show('ConfirmDelete')").button-without-styles
          //-       svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
          //-         path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
          .pt-1
            p(style="line-height: 32px;").text-start.font-16.mr-5.color-descartDocumentBack.mt-3.Subtitle Por favor adjunta los siguientes documentos:
            ol.olContainer.pl-5
              //- li(style="line-height: 25px;").Subtitle.text-start.listItem.mb-2 Orden médica por el frente y el revés.
              //- li(style="line-height: 25px;").Subtitle.text-start.listItem.mb-2 Resultados de exámenes relacionados (en caso de tenerlos).
              //- li(style="line-height: 25px;").Subtitle.text-start.listItem Copia de la historia clínica (en caso de tenerla a mano).
              li(style="line-height: 32px;").Subtitle.text-start.listItem Foto frente y respaldo solicitud de servicios médicos y/o foto orden médica una cara.
              li(style="line-height: 32px;").Subtitle.text-start.listItem Resultados de exámenes relacionados (en caso de tenerlos).
              li(style="line-height: 32px;").Subtitle.text-start.listItem Copia de la historia clínica (en caso de tenerla a mano).
              //- .circleCard.mr-3.d-flex.text-center.pb-4
          //- .fakeElementToScroll
  
        div(v-if="documents.length")
          //- p.ml-3.TextSection.text-start.mr-5.color-dark.ml-2 Desliza hacia la izquierda para agregar o visualizar más fotos
          .glider-contain.documents-zone
            vue-slick-carousel(:list="convertDataMapped" :key2="key2")
              .slick-item(
                v-for="(snap, key2) in convertDataMapped"
                :key="'col_' + key2"
                :id="key2 + 1"
                :class="convertDataMapped.length === 1 && 'centeredUniqElement'"
              )
                #tapDisabled.tapDisabled
                i.icon.fa.fa-times-circle(@click="() => handlerDeleteDoc(key2)")
                img(v-if="snap.isImg" :src="snap.file" class="snapshot" @click="showImageInModal(snap)")
                .snapshot(v-else @click="showImageInModal(snap)")
                  pdf(:src="snap.file")
                //- p.TextSection.text-truncate.w-100.descP.mt-1.text-start {{ snap.info.name }}
        //- .mx-auto(style="width: fit-content;" @click="onShowModal").text-center.container2.py-5
        //-   .containerIcons(style="background-color: #007bff")
        //-   p.buttonText.mt-1 Adjuntar documento
        .py-3
          b-button(:disabled="documents.length >= 20" variant="bluecolmedica" size="lg" type="button" @click="onShowModal").text-center.buttonDK.mt-0
            img(src="@/assets/kiosco46/plus.svg" alt="plus" style="margin-top: -5px !important;").plus.mr-3
            span.buttonText Adjuntar documento
          p.buttonText.mt-4(v-if="documents.length >= 20").text-center llegaste al máximo de documentos
        .pb-1.pt-3(v-if="documents.length" )
          //- goToView('ConfirmUpload')
          b-button(variant="bluecolmedica" block size="lg" type="button" @click="onShowContinue" style="width: fit-content;").SendTwo.text-center.AuthsBtn.my-2
            p.buttonText.AuthMeds.mb-0 Continuar
            //- | Enviar {{ documents.length }} documento{{ documents.length === 1 ? "" : "s" }}
            svg(width='11' height='18' viewbox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg').ml-4
              path(d='M2 2L8.99954 8.99954' stroke='#F4F4F4' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
              path(d='M2 15.9688L8.99954 8.96921' stroke='#F4F4F4' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
        div(v-show="false")
          input(type="file" accept="image/*" style="display: none" ref="image" @change="fileHandlerImage")
          input(type="file" accept="*/*" style="display: none" ref="file" @change="fileHandlerGeneral")
  
      b-modal(v-model="showModalContinue" centered=true hide-footer hide-header :no-close-on-backdrop="true" id="timer-init")
        .p-5
          p.Subtitle.text-start.listItem.mb-4 Tienes {{ documents.length }} documento{{ documents.length === 1 ? "" : "s" }} cargado{{ documents.length === 1 ? "" : "s" }}.
          p.m-0.Subtitle.text-start.listItem.mb-4 Ya puedes continuar para que te atendamos por videollamada.
          b-button(variant="bluecolmedica" block size="lg" type="button" @click="goToView({view: 'ConfirmUpload'})" style="width: 513px; display: flex; align-items: center; justify-content: center;").text-center.AuthsBtn.my-4
            p.buttonText.AuthMeds.mb-0 Continuar con videollamada
            svg(width='11' height='18' viewbox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg').ml-4
              path(d='M2 2L8.99954 8.99954' stroke='#F4F4F4' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
              path(d='M2 15.9688L8.99954 8.96921' stroke='#F4F4F4' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
          p.Subtitle.text-start.listItem.m-0.mb-4 Si necesitas adjuntar más documentos presiona el siguiente botón:
          b-button(variant="bluecolmedica" block size="lg" type="button" @click="onCloseModalContinue" style="width: 513px; display: flex; align-items: center; justify-content: center;").text-center.AuthsBtn.my-4
            svg.svgLeft(width='11' height='18' viewbox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg').mr-4
              path(d='M2 2L8.99954 8.99954' stroke='#F4F4F4' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
              path(d='M2 15.9688L8.99954 8.96921' stroke='#F4F4F4' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
            p.buttonText.AuthMeds.mb-0 Adjuntar más documentos
        div(v-if="!addDocumentShow")
          BottomTimer(:doThis="onShowAreYouThere")
      b-modal(v-model="showModalScan" centered=true hide-footer hide-header)
        .px-5.pt-5.pb-0(v-if="showModalScan")
          img(src="@/assets/kiosco46/cancel.svg" alt="cancel" @click="showModalScan = false").cancelIcon
          h3.Title.text-center
            strong.normalFont.strong Introduce el documento en la ranura
          h4.text-center.normalFont.Subtitle con las letras hacia arriba.
          img(src="@/assets/kiosco46/cropperGuide.png" alt="imgCropperGuide").imgCropperGuide
          .video-container
            video(autoplay preload)#videoStream
          .fakeHeight
            canvas(style="transform: rotate(-90deg);")#videoWebcam
          .pt-0
            b-button(variant="bluecolmedica" block size="lg" type="button" @click="onScanDoc" :disabled="!!!videoWebcam").text-center.AuthsBtn.mb-0.mt-3
              p.buttonText.AuthMeds.mb-0 Escanear documento
          div(v-if="!addDocumentShow")
            BottomTimer(:doThis="onShowAreYouThere" :limit="40")
      b-modal(id="ShowImage" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="position-relative w-auto h-auto")
          .pt-5(class="d-block text-center")
            img(:src="holdDocument" class="img-fluid max-image-hold" v-if="holdDocument")
            div(v-else) Sin vista previa del documento
        img(src="@/assets/kiosco46/cancel.svg" alt="cancel" @click="hiddenImageInModal").cancelIcon
        BottomTimer(:doThis="onShowAreYouThere")
  
      b-modal(id="ConfirmDelete" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="position-relative w-auto h-auto")
          div(class="d-block text-center")
            div(class="text-sizing-108") Al ir Atrás, perderas las fotos cargadas. Estas seguro?
          div(class="position-relative mt-3")
            b-button(@click="backView()" variant="bluecolmedica" size="sm" class="d-block mx-auto my-1 text-sizing-12") Si, ir Atrás
            b-button(@click="$bvModal.hide('ConfirmDelete')" variant="bluecolmedica" size="sm" class="d-block mx-auto my-1 text-sizing-12") No cancelar
      //- .zone-button
      //-   .mx-5
      //-     div
      //-       .text-center
      //-         span(v-if="!documents.length").TextSection.TitleZoned Tomar foto o adjuntar archivo
      //-       .p-2.d-flex.justify-content-center.w-100.mt-3.mb-1
      //-         .circleCard.mr-3.d-flex.text-center
      //-           .containerIcons(@click="simulateAttach")
      //-             svg(width='17' height='22' viewbox='0 0 17 22' fill='none' xmlns='http://www.w3.org/2000/svg')
      //-               path(d='M11.2554 0H1.2964C0.945693 0.0102983 0.612819 0.173702 0.370107 0.454704C0.127395 0.735706 -0.00554391 1.1116 0.0001773 1.50071V20.4993C-0.00554391 20.8884 0.127395 21.2643 0.370107 21.5453C0.612819 21.8263 0.945693 21.9897 1.2964 22H15.7036C16.0543 21.9897 16.3872 21.8263 16.6299 21.5453C16.8726 21.2643 17.0055 20.8884 16.9998 20.4993V6.22286L11.2554 0ZM10.625 7.07143V1.44571L15.7957 7.07143H10.625Z' fill='#081E3D')
      //-               rect(width='4.24991' height='22' fill='#081E3D')
      //-               rect(y='18.8555' width='16.9996' height='3.14286' fill='#081E3D')
      //-           p.textCard.mt-1 documento
      //-         .circleCard.ml-4.d-flex.text-center
      //-           .containerIcons(@click="simulateImagen")
      //-             svg(width='26' height='26' viewbox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg')
      //-               path(d='M13 16.25C14.7949 16.25 16.25 14.7949 16.25 13C16.25 11.2051 14.7949 9.75 13 9.75C11.2051 9.75 9.75 11.2051 9.75 13C9.75 14.7949 11.2051 16.25 13 16.25Z' fill='#081E3D')
      //-               path(d='M18.2318 4.33464L16.2493 2.16797H9.74935L7.76685 4.33464H2.16602V21.668H23.8327V4.33464H18.2318ZM12.9993 18.418C10.0093 18.418 7.58268 15.9913 7.58268 13.0013C7.58268 10.0113 10.0093 7.58464 12.9993 7.58464C15.9893 7.58464 18.416 10.0113 18.416 13.0013C18.416 15.9913 15.9893 18.418 12.9993 18.418Z' fill='#081E3D')
      //-           p.textCard.mt-1 Cámara
    b-modal(
      id="invalid-mime"
      footerClass='p-2 border-top-0'
      headerClass='p-2 border-bottom-0'
      centered=true
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    )
      h6 Puedes cargar documentos de maximo 10Mb de peso.
      h6.text-error Error: {{ errorModalType ? "Su documento no tiene el formato pdf, png, jpg, jpeg o tiff" : "Su documento pesa más de 10MB" }}
      .buttons.mt-4.mimeErrorCloser
        b-button.ml-2(@click="toogleInvalidModal") Cerrar
      BottomTimer(:doThis="onShowAreYouThere")
  
    //- b-modal(
    //-   id="invalid-size"
    //-   footerClass='p-2 border-top-0'
    //-   headerClass='p-2 border-bottom-0'
    //-   centered=true
    //-   hide-footer
    //-   no-close-on-esc
    //-   no-close-on-backdrop
    //-   hide-header-close
    //- )
    //-   h6 No puedes cargar este archivo. El tamaño máximo del archivo es de 10 MB.
    //-   .buttons.mt-4.sizeErrorCloser
    //-     b-button.ml-2(@click="toogleInvalidModal") Cerrar
  </template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import chunk from "lodash/chunk";
import Header from "./components/Header";
import Spinner from "../components/Spinner";
import AreYouThere from "./components/AreYouThere";
// import { RlCarousel, RlCarouselSlide } from "vue-renderless-carousel";
import VueSlickCarousel from "./components/VueSlickCarousel";
import Timer from "./components/Timer.vue";
import BottomTimer from "./components/BottomTimer";

// icons
import Camera from "mdi-vue/Camera.vue";
import Attach from "mdi-vue/Attachment.vue";
import Rotate from "mdi-vue/RotateRight";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold.vue";
import Cogs from "mdi-vue/Cogs";

import Compressor from "compressorjs";
import "@/assets/cropper.css";
import Cropper from "cropperjs";
import pdf from "vue-pdf";

export default {
  name: "UploadFiles",
  components: {
    Header,
    Camera,
    Attach,
    Rotate,
    Back,
    Continue,
    Cogs,
    Spinner,
    VueSlickCarousel,
    pdf,
    Timer,
    BottomTimer,
    AreYouThere
    // VueSlickCarousel
  },
  watch: {
    convertDataMapped(newVal, oldVal) {
      console.warn(newVal);
      if (newVal && newVal.length && newVal.length > oldVal.length) {
        const timer = setTimeout(() => {
          const $vueSlickCarousel = document.getElementById("VueSlickCarousel");
          $vueSlickCarousel.scrollTo($vueSlickCarousel.scrollWidth, 0);
          clearTimeout(timer);
        }, 10);
      }
    },

    showModalScan(val) {
      if (!val) {
        if (this.timer20) {
          clearTimeout(this.timer20);
          this.timer20 = null;
        }
      }
    }
  },
  data: () => ({
    holdIsImagen: false,
    holdDocument: null,
    holdDocumentInfo: null,
    holdLoading: true,
    compressImagen: false,
    didGo: false,

    infoSizeImagen: null,
    addDocumentShow: false,
    showAreYouThere: false,

    widthBox: null,
    showAreYouThereOriginal: false,
    timer20: null,
    heightBox: null,
    showModalContinue: false,
    urlData: "",
    cropper: null,
    modeCropper: false,
    slide: 0,
    errorModalType: false,
    showModalScan: false,
    timerContinue: null,

    videoStream: undefined,
    videoWebcam: undefined,
    contextWebcam: null,
    interval: null,
    modePicture: true,
    errorVideo: false,
    key2: 0,

    validMimeTypes: [
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/octet-stream",
      "image/",
      // "video/",
      // "audio/",
      "application/pdf"
    ],

    loadingCompressor: false,
    rotateCompressorError: false,
    isntIntersection: false
  }),
  mounted() {
    // this.requestMedia();

    document.body.addEventListener("click", this.clickModal);
  },
  computed: {
    ...mapState({
      documents: state => state.autoservice.files,
      explanation: state => state.autoservice.explanation,
      stopTimer: state => state.autoservice.stopTimer,
      containerImageDocs: state =>
        state.virtualrow_atril_video.containerImageDocs,
      showProgressBar: state => state.virtualrow_atril_video.showProgressBar,
      timer: state => state.virtualrow_atril_video.timer,
      env: state => state.env
    }),
    convertData() {
      return chunk(this.documents, 2);
    },
    convertDataMapped() {
      return this.documents ? this.documents.map(data => data) : [];
    },
    changedTitle() {
      return this.documents.length ? "Enviar documentos" : "Adjunta documentos";
    }
  },
  destroyed() {
    if (this.timerContinue) clearInterval(this.timerContinue);

    document.body.removeEventListener("click", this.clickModal);
  },
  methods: {
    ...mapActions({
      goToView: "virtualrow_atril_video/goToView",
      removeScan: "autoservice/removeFile",
      addScan: "autoservice/addFile",
      clearDocs: "autoservice/clearDocs",
      putLog: "log/put",
      onStopTimer: "autoservice/onStopTimer"
    }),
    ...mapMutations({
      setState: "virtualrow_atril_video/setState"
    }),

    toogleInvalidModal() {
      this.$bvModal.hide("invalid-mime");
      this.requestMedia();
      // this.$bvModal.hide("invalid-size");
    },

    onShowModal() {
      this.clearAll();
      this.showModalScan = true;
      this.requestMedia();
    },

    // simulate Attach
    async simulateAttach() {
      this.simulateClick(this.$refs.file);
    },
    handlerDeleteDoc(key2) {
      this.key2 = key2;
      this.removeScan(key2);
    },
    clickModal() {
      const timer = setTimeout(() => {
        if (this.showModalScan) {
          if (this.timer20) {
            clearTimeout(this.timer20);
            this.timer20 = null;
          }

          if (this.showModalScan) {
            this.timer20 = setTimeout(() => {
              console.log("CLICK MODAL 1");
              this.showAreYouThere = true;
              clearTimeout(this.timer20);
              this.timer20 = null;
            }, 40000);
          } else {
            this.timer20 = setTimeout(() => {
              console.log("CLICK MODAL 2");
              this.showAreYouThere = true;
              clearTimeout(this.timer20);
              this.timer20 = null;
            }, 15000);
          }
        }
        clearTimeout(timer);
      }, 500);
    },
    async simulateImagen() {
      this.simulateClick(this.$refs.image);
    },
    onRotation(deg) {
      this.cropper.rotate(deg);
    },
    onCancel() {
      // const $container = document.querySelector(".cropper-canvas");
      // $container.classList.remove("rotateWidth");
      // $container.classList.add("rotateWidthCancel");
      this.modeCropper = false;
      this.initialCropper();
    },
    onAccept() {
      const newUrl = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");

      this.urlData = newUrl;
      const timer = setTimeout(() => {
        this.onCancel();
        clearTimeout(timer);
      }, 0);
    },
    clearAll() {
      this.contextWebcam = null;
      this.videoStream = undefined;
      this.videoWebcam = undefined;

      this.interval = null;

      this.modePicture = true;
      this.src = "";
      this.modeCropper = false;
    },

    onCloseModalContinue() {
      this.showModalContinue = false;
      this.onStopTimer(false);
      if (this.timerContinue) clearInterval(this.timerContinue);
      this.timerContinue = null;
    },

    onShowContinue() {
      this.showModalContinue = true;
      this.timerContinue = setInterval(() => {
        document.body.click();
      }, 10);
    },

    onSuccessVideo(stream) {
      try {
        this.videoStream = document.getElementById("videoStream");
        this.videoWebcam = document.getElementById("videoWebcam");

        this.contextWebcam = this.videoWebcam.getContext("2d");
        setTimeout(() => {
          this.videoStream.srcObject = stream;
          // const settings = stream.getVideoTracks()[0].getSettings();

          this.videoStream.onloadedmetadata = () => {
            // this.videoWebcam.width = this.videoStream.videoWidth;
            // this.videoWebcam.height = this.videoStream.videoHeight;
            // this.videoWebcam.width = settings.width;
            // this.videoWebcam.height = settings.height;
            // this.videoWebcam.width = "1000";
            // this.videoWebcam.height = "1600";
            this.videoWebcam.width = this.videoStream.videoWidth;
            this.videoWebcam.height = this.videoStream.videoHeight;
          };
          this.videoStream.play();
          // this.initDrawCanvas();
        }, 500);
      } catch (error) {
        console.error("ERROR INIT SCAN CAM");
        console.log(error);
      }
    },

    onErrorVideo(error) {
      console.error(error);
      this.errorVideo = true;
    },

    requestMedia() {
      this.modePicture = true;
      navigator.mediaDevices.enumerateDevices().then(devices => {
        let cameras = devices.filter(dev => dev.kind === "videoinput");
        // check config cameras
        let kioscos = this.env.VUE_APP_KIOSCOS;
        let params = this.$route.query;
        let camerasScan = [];
        if (kioscos[params.branchid]) {
          camerasScan = cameras.filter(v =>
            v.label
              .toLowerCase()
              .includes(
                kioscos[params.branchid].cameras?.documents?.toLowerCase()
              )
          );
        } else {
          camerasScan = cameras.filter(v => v.label.includes("Q800L"));
        }
        let device;
        if (camerasScan.length >= 1) device = camerasScan[0];
        else device = cameras[0];
        console.log("device");
        console.log(device);
        // const constraints = {
        //   width: 1920,
        //   height: 1080,
        //   aspectRatio: 1.777777778,
        //   video: {
        //     mandatory: {
        //       sourceId: device.deviceId
        //     }
        //   }
        // };
        const constraints = {
          video: {
            deviceId: device.deviceId,
            width: 1920,
            height: 1080
          }
        };
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(this.onSuccessVideo)
          .catch(this.onErrorVideo);
      });
    },
    onDiscard() {
      // this.requestMedia();
      this.descartDocumentBack(true);
      this.clearAll();
      const timer = setTimeout(() => {
        this.onShowModal();
        clearTimeout(timer);
      }, 150);
    },
    onScanDoc() {
      this.videoStream.pause();

      this.urlData = this.videoWebcam.toDataURL();
      this.modePicture = false;

      // this.videoWebcam.width = "1080";
      // this.videoWebcam.height = "1920";
      // this.videoWebcam.style.display = "none";

      // picture in canvan for rotate actions
      this.contextWebcam?.drawImage(this.videoStream, 0, 0);

      const timeout = setTimeout(() => {
        // this.$refs.capture.style.display = "none";
        this.videoWebcam.toBlob(blob => {
          let file = new File([blob], "fileName.jpg", { type: "image/jpeg" });
          this.fileHandler({ files: [file] });
          // this.showModalScan = false;
        });

        clearTimeout(timeout);
      }, 0);
    },
    activateCropper(isHoldLoading) {
      const $content = document.querySelector(".cropper-canvas");
      if (isHoldLoading) {
        this.holdLoading = false;
        this.modeCropper = true;
        $content.classList.remove("cropper-canvas-without-crop");
      }
      const newUrl = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");

      this.urlData = newUrl;

      const timeout = setTimeout(() => {
        this.descartDocumentBack(false);
        this.cropper = new Cropper(this.$refs.fileImage, {
          autoCrop: true,
          viewMode: 1,
          movable: false,
          dragMode: "move",
          background: false,
          cropBoxMovable: true,
          cropBoxResizable: true,
          crop(event) {
            this.widthBox = event.detail.x;
            this.heightBox = event.detail.y;
          }
        });
        clearTimeout(timeout);
      }, 10);
    },
    onShowAreYouThere() {
      this.showAreYouThere = true;
    },
    restartTimerWaiting() {
      const time = setTimeout(() => {
        if (
          this.showModalScan ||
          document.querySelector("#AddDocument").style.display === "none"
        ) {
          document.querySelector(".modal-content")?.click();
        }
        if (document.querySelector("#AddDocument").style.display === "block") {
          document.querySelector("#AddDocument").click();
        }
        clearTimeout(time);
      }, 5000);
      this.showAreYouThere = false;
      this.validationRestart();
    },
    validationRestart() {
      this.showAreYouThere = false;
      if (this.timer20) {
        clearTimeout(this.timer20);
        this.timer20 = null;
      }
      if (this.showModalScan) {
        this.timer20 = setTimeout(() => {
          this.showAreYouThere = true;
          clearTimeout(this.timer20);
          this.timer20 = null;
        }, 40000);
      } else {
        this.timer20 = setTimeout(() => {
          this.showAreYouThere = true;
          clearTimeout(this.timer20);
          this.timer20 = null;
        }, 15000);
      }
    },
    simulateClick(elem) {
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      });
      elem.dispatchEvent(evt);
    },
    // handlers
    fileHandlerImage(event) {
      this.fileHandler(event.target);
    },
    fileHandlerGeneral() {
      this.fileHandler(this.$refs.file);
    },
    async fileHandler(reference) {
      const files = reference.files;
      if (!files[0]) return;
      const infoFile = {
        name: files[0].name,
        ext: files[0].name.split(".")[files[0].name.split(".").length - 1],
        mime: files[0].type,
        size: files[0].size
      };
      const isValidMime = this.validMimeTypes.reduce(
        (prev, curr) => infoFile.mime.indexOf(curr) === 0 || prev,
        false
      );
      const isValidSize = infoFile.size <= 10000000;
      // it was for isValidSize var: infoFile.size < 10000000
      if (!isValidMime) {
        this.putLog({
          name: "UploadFiles. Tipo de archivo inválido.",
          checked: false
        });
        this.errorModalType = true;
        this.$bvModal.show("invalid-mime");
        return;
      }
      if (!isValidSize) {
        this.putLog({
          name: "UploadFiles. Peso de archivo inválido.",
          checked: false
        });
        // this.$bvModal.show("invalid-size");
        this.errorModalType = false;
        this.$bvModal.show("invalid-mime");
        return;
      }
      this.holdIsImagen = files[0].type.indexOf("image") !== -1;
      this.holdDocumentInfo = infoFile;

      this.compressImagen = true;
      let fileToCompress = {};
      if (this.holdIsImagen) {
        try {
          fileToCompress = await this.compressorJsImage(files[0]);
          console.warn(fileToCompress, "file");
        } catch (error) {
          fileToCompress = files[0];
        }
        this.urlData = URL.createObjectURL(fileToCompress);

        const timeout = setTimeout(() => {
          this.initialCropper(true);
          clearTimeout(timeout);
        }, 10);

        this.holdDocument = await this.readed(fileToCompress);
      } else {
        this.holdDocument = await this.readed(files[0]);
      }
      this.putLog({
        name: "UploadFiles. Archivo comprimido."
      });
      this.infoSizeImagen = {
        uncompress: infoFile.size,
        compress: fileToCompress.size || infoFile.size
      };
      this.compressImagen = false;
      document.querySelector("#AddDocument").style.display = "block";
      this.addDocumentShow = true;
      document.querySelector("#OverlayDocument").style.display = "block";
      reference.value = "";
    },

    descartDocumentBack(discard) {
      this.cropper?.destroy();
      this.cropper = null;
      if (discard) this.descartDocument();
    },

    initialCropper(isStarter) {
      this.descartDocumentBack(false);
      const self = this;
      this.cropper = new Cropper(this.$refs.fileImage, {
        autoCrop: false,
        viewMode: 1,
        movable: false,
        dragMode: "move",
        background: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        crop(event) {
          this.widthBox = event.detail.x;
          this.heightBox = event.detail.y;

          self.activateCropper(false);
          const timer = setTimeout(() => {
            self.onCancel();
            clearTimeout(timer);
          }, 10);
          // const $container = document.querySelector(".cropper-canvas");
          // const $image = $container.querySelector("img");
          // // const width = $container.offsetWidth;
          // const height = $container.offsetHeight;
          // if (height <= 448) {
          //   $image.classList.add("rotateWidth");
          // } else {
          //   $image.classList.remove("rotateWidth");
          // }
        }
      });
      const timer = setTimeout(() => {
        const $content = document.querySelector(".cropper-canvas");
        $content.classList.add("cropper-canvas-without-crop");
        clearTimeout(timer);
      }, 1000);
      // this.cropper.move(0);
      if (isStarter) {
        const tape = document.querySelector(".tapeControl");
        tape.style.display = "block";
        const timer = setTimeout(() => {
          this.onRotation(-90);
          tape.style.display = "none";
          clearTimeout(timer);
        }, 500);
      }
    },

    readed(file) {
      return new Promise((res, rej) => {
        var reader = new FileReader();
        reader.onload = () => res(reader.result);
        reader.onerror = err => rej(err);
        reader.readAsDataURL(file);
      });
    },

    compressorJsImage(file) {
      return new Promise((res, rej) => {
        new Compressor(file, {
          quality: 0.9,
          success(result) {
            res(result);
          },
          error(err) {
            rej(err);
          }
        });
      });
    },

    addDocument() {
      if (this.cropper) {
        this.addScan({
          info: {
            ...this.holdDocumentInfo,
            b64: this.cropper
              .getCroppedCanvas()
              .toDataURL("image/jpeg")
              .replace(/data:[\w/]+;base64,/g, "")
          },
          file: this.cropper.getCroppedCanvas().toDataURL("image/jpeg"),
          isImg: this.holdIsImagen,
          dataImage: this.cropper.getCanvasData()
        });
      } else {
        this.addScan({
          info: {
            ...this.holdDocumentInfo,
            b64: this.holdDocument.replace(/data:[\w/]+;base64,/g, "")
          },
          file: this.holdDocument,
          isImg: this.holdIsImagen
        });
      }
      this.descartDocument();
      this.showModalScan = false;
    },

    descartDocument() {
      document.querySelector("#AddDocument").style.display = "none";
      this.addDocumentShow = false;
      document.querySelector("#OverlayDocument").style.display = "none";
      this.holdDocument = null;
    },

    async rotateImage() {
      this.loadingCompressor = true;
      try {
        const rotateData = await new Promise(res =>
          this.rotate(this.holdDocument, 90, res)
        );
        this.loadingCompressor = false;
        this.holdDocument = rotateData;
      } catch (error) {
        this.loadingCompressor = false;
        this.rotateCompressorError = true;
        console.error("Cant compress image", error);
      }
    },

    rotate(srcBase64, degrees, callback) {
      const canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let image = new Image();
      image.onload = function() {
        canvas.width = degrees % 180 === 0 ? image.width : image.height;
        canvas.height = degrees % 180 === 0 ? image.height : image.width;
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((degrees * Math.PI) / 180);
        ctx.drawImage(image, image.width / -2, image.height / -2);
        callback(canvas.toDataURL());
        this.loadingCompressor = false;
      };
      image.src = srcBase64;
    },

    showImageInModal(snap) {
      this.holdIsImagen = snap.isImg;
      this.holdDocument = snap.file;
      this.$bvModal.show("ShowImage");
    },

    initDrawCanvas() {
      // this.interval = setInterval(() => {
      //   this.contextWebcam?.drawImage(this.videoStream, 0, 0);
      //   if (!this.modePicture) {
      //     clearInterval(this.interval);
      //   }
      // }, 500);
    },

    hiddenImageInModal() {
      this.$bvModal.hide("ShowImage");
      this.holdIsImagen = null;
      this.holdDocument = null;
    },

    backView() {
      this.clearDocs();
      this.goToView({ view: "VerificationData" });
    }
  }
};
</script>

<style scoped>
#videoStream {
  /* display: none; */
  /* width: 1393px;
    height: 1260px; */
  width: 100%;
  height: 300px;
  margin-left: -130px;
  /* transform: rotate(-90deg); */
  object-position: center;
  object-fit: contain;
  margin-bottom: -9px;
  /* padding: 50px 0; */
  /* margin-left: -476px; */
}

#videoWebcam {
  /* display: none; */
  visibility: hidden;
}

.cancelIcon {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 20px;
  height: 21px;
}

.textMessage {
  font-size: 1.1rem;
  color: gray;
}

.contain {
  /* position: relative; */
  width: 100%;
  height: calc(100% - (58px + 22px + 50px));
}

/* Zone Documents */
.documents-zone {
  position: relative;
  /* overflow-y: auto; */
  width: 100%;
  /* height: 34%; */
}

/* snapshot container */
.snapshot-row {
  position: relative;
  width: auto;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 28px;
}

.snapshot-col {
  width: calc(50% - 10px);
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* padding: 5px; */
}

.snapshot {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.icon {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 33px;
  background: white;
  color: #e2574c;
  border-radius: 50%;
  cursor: pointer;
}

.centeredUniqElement {
  margin-left: 51.5px;
}

/* Button Zone */
.zone-button {
  position: fixed;
  bottom: 2px;
  max-width: 411px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 25px 25px 0 0;
  background-color: #081e3d;
  width: 100%;
  /* position: relative; */
}

.TitleZoned {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  max-width: fit-content;
  max-width: 411px;
  width: 100%;
  padding: 7px 0px;
  background-color: var(--color-white);
}

.size-buttons svg {
  fill: #00599d;
}

.size-buttons {
  margin: 0px auto;
  position: relative;
  display: block;
}

.icon-container {
  border-radius: 10px;
  margin: 10px;
  padding: 10px 0px;
  border: 1px solid #ececec;
  width: auto;
  color: rgb(114, 114, 114);
  background: #ececec;
}

.listItem {
  line-height: 32px;
}

.olContainer {
  margin: 0px 18px;
  margin-right: 20px;
  display: grid;
  gap: 35px;
}

.max-image-hold {
  height: auto;
  width: 100%;
  max-width: 70vw;
  max-height: 70vh;
  object-fit: cover;
}

.imgCropperGuide {
  width: 100%;
  height: 180px;
  object-fit: contain;
  margin-bottom: -142px;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: 0;
}

.containerIcons {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #081e3d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleCard {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.circleCard div {
  cursor: pointer;
}

.textCard {
  color: #768191;
  font-size: 28px;
}

.ModalContent {
  position: fixed;
  z-index: 20000000000;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  background-color: #081e3d;
  display: none;
  width: 100%;
  max-width: 637px;
  overflow: auto;
  height: 1014px;
  bottom: 137px;
}

.ModalContent .BottomTimer {
  background-color: white;
}

/* .Overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 199;
        background-color: ;
      } */

.containerImage {
  height: 100%;
  width: 100%;
  max-height: calc(100% - 200px);
  max-width: 382.5px;
}

.containerImage img {
  /* This rule is very important, please don't ignore this */
  max-width: 370px;
  max-height: 370px;
  width: 100%;
  object-fit: cover;
}

/* width: 416px !important; */

.Background {
  background: #081e3d;
  height: calc(100% - 56px);
}

.iconCrop {
  width: 33px;
  height: 33px;
  object-fit: contain;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.buttonDK {
  max-width: 456px;
  margin: auto;
  display: block;
  margin-top: 29px;
  line-height: 65px;
  padding: 9px 3.5rem;
  font-weight: 600;
}

.buttonTitle {
  font-size: 20.35px;
}

.iconCenteredArrow {
  right: 17.2px;
  top: 50%;
  transform: translateY(-50%);
}

.descP {
  white-space: nowrap !important;
}

.text-start {
  text-align: start;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20000000000;
  background-color: rgba(0, 0, 0, 0.507);
}

.DescDocs {
  font-size: 18px;
  line-height: 24px;

  /* RESPUESTAS AND ACCENT */

  color: #081e3d;
}

#timer-init___BV_modal_content_ {
  top: 50%;
  width: 765px;
}

.fakeElementToScrollTwo {
  height: 50px;
}

.text-error {
  color: #e2574c;
}

.UploadFilesPage {
  background-color: #f2f4f8;
  /* padding: 1.5rem; */
  width: 100%;
  max-width: 814px;
  margin: auto;
  height: auto;
  padding: 50px;
}

.down {
  position: absolute;
  bottom: 0;
}

.containerPage {
  padding: 0 1.5rem;
}

.AuthMeds {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 65px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.AuthsBtn {
  margin: 100px auto;
  width: 400px;
  padding: 9px 3.5rem;
  line-height: 65px;
  font-weight: 600;
}

.video-container {
  width: 618px;
  height: 642px;
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -90px;
  position: relative;
  z-index: -1;
}

.svgLeft {
  transform: rotate(180deg);
}

.modal-content {
  top: 50%;
}

.SendTwo {
  max-width: 275px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tapeControl {
  width: 521px;
  height: 690px;
  position: absolute;
  background: #081e3d;
  left: 0;
  z-index: 1000;
  display: none;
  top: 0;
}

.fakeHeight {
  min-height: 517px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 449px;
  display: none;
}

.fakeHeight canvas {
  transform-origin: center;
  width: 50px;
  height: 50px;
}

@media (max-width: 600px) {
  .contain {
    margin-top: 0px;
    height: calc(100% - (58px + 22px));
  }
}
</style>
