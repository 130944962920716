<template lang="pug">
div
  .IsOlderOverlay
  #IsOlderModal.IsOlderModal.p-4.text-center
    p.buttonText.pb-3 Este servicio es para mayores de 60 años
    b-button(variant="bluecolmedica" block size="lg" type="button" @click="() => goToView({ view: 'Autenticate' })").AuthsBtn.text-center
      p.buttonText.m-0 Ingresar nuevos datos
    .p-3(v-if="view === 'IsOlder' && !showAreYouThere")
      BottomTimer(:doThis="onShowAreYouThere" $element="#IsOlderModal")
    div(v-if="showAreYouThere")
      AreYouThere(@restartAreYouThere="restartTimerWaiting")
</template>

<script>
import { mapActions, mapState } from "vuex";
import BottomTimer from "./components/BottomTimer";
import AreYouThere from "./components/AreYouThere.vue";

export default {
  name: "IsOlder",

  data() {
    return {
      showAreYouThere: false,
      timer20: null
    };
  },

  components: {
    BottomTimer,
    AreYouThere
  },

  computed: {
    ...mapState({
      view: state => state.virtualrow_atril_video.view,
      env: state => state.env,
      showProgressBarAun: state =>
        state.virtualrow_atril_video.showProgressBarAun,
      timerAun: state => state.virtualrow_atril_video.timerAun
      // options: state => state.olderAdult.tipide,
      // branch_id: state => state.branchInfo,
      // showUserInactive: state => state.olderAdult.showUserInactive,
      // showUserOlder: state => state.olderAdult.showUserOlder,
      // client: state => state.olderAdult.client,
      // charging: state => state.olderAdult.charging
    })
  },

  methods: {
    ...mapActions({
      goToView: "virtualrow_atril_video/goToView"
    }),
    onShowAreYouThere() {
      this.showAreYouThere = true;
    },
    restartTimerWaiting() {
      this.showAreYouThere = false;
      this.validationRestart();
    },
    validationRestart() {
      if (this.timer20) {
        clearTimeout(this.timer20);
        this.timer20 = null;
      }

      this.timer20 = setTimeout(() => {
        this.showAreYouThere = true;
        clearTimeout(this.timer20);
        this.timer20 = null;
      }, 120000);
    }
  }
};
</script>

<style lang="scss" scoped>
.IsOlderOverlay {
  background-color: rgba(0, 0, 0, 0.322);
  z-index: 1050;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}

.IsOlderModal {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1220;
  background-color: aliceblue;
  border-radius: 20px;
}

.AuthsBtn {
  margin: auto;
  width: 450px;
  padding: 9px 3.5rem;
  line-height: 65px;
  font-weight: 600;
}
</style>
